<template>
  <v-container>
    <v-form ref="form">
      <h4>Sopimuksen nimi</h4>
      <v-row dense>
        <v-col cols="12" sm="6" md="5" lg="3">
          <v-text-field v-model="name" dense outlined :rules="required" label="Sopimuksen nimi">
          </v-text-field> </v-col
      ></v-row>

      <h4>Osapuolet</h4>

      <v-row dense>
        <v-col cols="12" sm="6" md="5" lg="3" xl="2">
          <v-autocomplete
            v-model="tenant"
            :items="activeTenants"
            item-text="name"
            item-value="_id"
            label="Vuokralainen"
            no-data-text="Vuokralaisen nimi"
            outlined
            dense
            return-object
            @input.native="getTenants"
            :rules="required"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="5" lg="3">
          <v-autocomplete
            v-model="otherTenants"
            :items="activeTenants"
            label="Muut vuokralaiset"
            item-text="name"
            item-value="_id"
            no-data-text="Vuokralaisen nimi"
            outlined
            multiple
            small-chips
            deletable-chips
            return-object
            hide-details
            :search-input.sync="searchInput"
            @change="searchInput = ''"
            @input.native="getTenants"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="5" lg="3">
          <v-autocomplete
            v-model="landlords"
            :items="activeLandlords"
            label="Vuokranantajat tai toimeksiantajat"
            item-text="name"
            item-value="_id"
            no-data-text="Vuokranantaja tai toimeksiantajan nimi"
            outlined
            multiple
            small-chips
            deletable-chips
            return-object
            hide-details
            :search-input.sync="searchInput2"
            @change="updateLandlords"
            @input.native="getLandlords"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="mt-2" dense>
        <v-col cols="12" sm="6" md="5" lg="3" xl="2">
          <h4 class="mb-1">Vuokrakohde</h4>
          <v-autocomplete
            v-model="apartment"
            :items="activeApartments"
            :item-text="fullAddress"
            item-value="_id"
            label="Vuokrakohde"
            no-data-text="Vuokrakohteen osoite"
            outlined
            dense
            small-chips
            return-object
            deletable-chips
            hide-details
            :search-input.sync="searchInput3"
            @change="searchInput3 = ''"
            @input.native="getApartments"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="5" lg="3">
          <h4 class="mb-1">Avaimet</h4>
          <v-autocomplete
            v-model="keys"
            :items="activeKeys"
            :item-text="keyFullName"
            item-value="_id"
            label="Avaimet"
            no-data-text="Avaimen koodi"
            outlined
            dense
            multiple
            small-chips
            deletable-chips
            hide-details
            return-object
            :search-input.sync="searchInput4"
            @change="searchInput4 = ''"
            @input.native="getKeys"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { eventBus } from "../../main";
import { debounce } from "../../utils/helpers";
import mixins from "../../mixins/mixins.js";

export default {
  mixins: [mixins],

  data() {
    return {
      landlordInput: "",
      tenantInput: "",
      apartmentInput: "",
      keyInput: "",
      searchInput: "",
      searchInput2: "",
      searchInput3: "",
      searchInput4: "",
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("tenant", ["activeTenants", "currentTenant"]),
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("account", ["activeLandlords"]),
    ...mapState("keys", ["activeKeys"]),
    ...mapState("contract", ["refRentalContract"]),
    ...mapState("contractmodel", ["selectedContractModel"]),

    name: {
      get() {
        return this.$store.state.othercontract.contract.name;
      },
      set(val) {
        this.$store.state.othercontract.contract.name = val;
      },
    },

    tenant: {
      get() {
        return this.$store.state.othercontract.contract.tenant;
      },
      set(val) {
        this.$store.state.othercontract.contract.tenant = val;
      },
    },

    otherTenants: {
      get() {
        return this.$store.state.othercontract.contract.otherTenants;
      },
      set(val) {
        this.$store.state.othercontract.contract.otherTenants = val;
      },
    },

    landlords: {
      get() {
        return this.$store.state.othercontract.contract.landlords;
      },
      set(val) {
        this.$store.state.othercontract.contract.landlords = val;
      },
    },

    signYourSelf: {
      get() {
        return this.$store.state.othercontract.contract.signYourSelf;
      },
      set(val) {
        this.$store.state.othercontract.contract.signYourSelf = val;
      },
    },

    apartment: {
      get() {
        return this.$store.state.othercontract.contract.apartment;
      },
      set(val) {
        this.$store.state.othercontract.contract.apartment = val;
      },
    },

    keys: {
      get() {
        return this.$store.state.othercontract.contract.keys;
      },
      set(val) {
        this.$store.state.othercontract.contract.keys = val;
      },
    },
  },

  watch: {
    // If createOtherContract.vue component watcher fo this.$route.query.tenant
    currentTenant(val) {
      this.name = `Avainluovutus ${val.name}`;
      this.tenant = { ...val };
      this.$store.state.tenant.activeTenants = [{ ...val }];
    },

    landlordInput: debounce(function (newVal) {
      this.searchAllLandlords(`/api/v1/account/search-landlords?search=${newVal}`);
    }, 1000),

    tenantInput: debounce(function (newVal) {
      this.searchTenant(`/api/v1/tenant/search-tenant?search=${newVal}`);
    }, 1000),

    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),

    keyInput: debounce(function (newVal) {
      this.searchKey(`/api/v1/key/search-key?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("tenant", ["searchTenant", "getTenantsById"]),
    ...mapActions("account", ["searchAllLandlords", "getLandlordsById"]),
    ...mapActions("apartment", ["searchApartment", "getApartmentsById"]),
    ...mapActions("keys", ["searchKey"]),

    getTenants(event) {
      this.tenantInput = event.target.value;
    },

    getLandlords(event) {
      this.landlordInput = event.target.value;
    },

    getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    getKeys(event) {
      this.keyInput = event.target.value;
    },

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    keyFullName(item) {
      let rights = "";
      let name = "";

      item.apartments.forEach((el, index) => {
        if (index < item.apartments.length - 1) {
          rights += el.apartmentNumber
            ? `${el.address} (${el.apartmentNumber}), `
            : `${el.address}, `;
        } else {
          rights += el.apartmentNumber ? `${el.address} (${el.apartmentNumber})` : `${el.address}`;
        }
      });

      if (rights) {
        name = `${item.code} | ${rights}`;
      } else {
        name = item.code;
      }

      return name;
    },

    async setRefRentalContractValues() {
      try {
        const contract = this.refRentalContract;
        const model = this.selectedContractModel;

        // // Set initial values to arrays
        const landlordIds = contract.landlords.map((el) => el.landlordId._id);
        const tenantIds = [
          contract.tenant.tenantId._id,
          ...contract.otherTenants.map((el) => el.tenantId._id),
        ];
        const apartmentIds = [contract.apartment.id._id];

        await Promise.all([
          this.getLandlordsById(landlordIds),
          this.getTenantsById(tenantIds),
          this.getApartmentsById(apartmentIds),
        ]);

        const apartmentAddress = contract.apartment.id.address;
        const apartmentNumber = contract.apartment.id.apartmentNumber
          ? `, ${contract.apartment.id.apartmentNumber}`
          : "";

        this.name = `${model.name} ${contract.tenant.name} | ${apartmentAddress}${apartmentNumber}`;
        this.landlords = contract.landlords.map((el) => el.landlordId);
        this.updateLandlords();
        this.tenant = contract.tenant.tenantId;
        this.otherTenants = contract.otherTenants.map((el) => el.tenantId);
        this.apartment = contract.apartment.id;

        // Set model id
        this.$store.state.othercontract.contract.modelId = model._id;
        // Get template ready
        this.$emit("getpreviewtemplateready");
      } catch (err) {
        this.showPopup(err);
      }
    },

    updateLandlords() {
      const landlords = this.landlords.map((landlord) => {
        landlord.onModel =
          String(this.$store.state.account.currentUser.currentAccount._id) == String(landlord._id)
            ? "Account"
            : "Partner";
        landlord.landlordId = landlord._id;
        return landlord;
      });

      // set to state
      this.landlords = landlords;
      this.searchInput2 = "";
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        eventBus.$emit("validatedata", true);
      } else {
        eventBus.$emit("validatedata", false);
      }
    },
  },
};
</script>

<style scoped></style>
